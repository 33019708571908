<template>
    <v-card class="ma-5">
        <v-form v-model="valid" ref="form">
            <v-container>
                <v-row class="my-4 align-center">
                    <h1 class="ms-2">FICHA CITA</h1>
                    <v-spacer></v-spacer>
                    <v-btn to="/citas" color="primary" class="me-2"><v-icon>mdi-arrow-left</v-icon>VOLVER</v-btn>
                    <v-btn v-if="idCita" @click="del" color="error" class="me-2"><v-icon>mdi-trash-can-outline</v-icon>ELIMINAR</v-btn>
                    <v-btn @click="duplicar" color="info" class="me-2"><v-icon>mdi-content-copy</v-icon>DUPLICAR</v-btn>
                </v-row>
                <AuxInput class="mx-2 no-gutters" title="DÍA">
                    <v-menu ref="menu" v-model="menu" :return-value.sync="date" transition="scale-transition" offset-y
                        min-width="auto" :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :rules="[rules.req]" :value='cita.fecha != null ? ((new Date(cita.fecha).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(cita.fecha).getDate()).slice(-2)) + (new Date(cita.fecha).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(cita.fecha).getFullYear()) : null' append-icon="mdi-calendar" readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker @click:date="menu = false" no-title v-model="cita.fecha" :first-day-of-week="1" scrollable>
                        </v-date-picker>
                    </v-menu>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="HORA INICIO">
                    <v-menu
                        ref="menu"
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            filled
                            :rules="[rules.req]"
                            dense
                            clearable
                            hide-details
                            v-model="cita.hInicio"
                            class="v-superdense-input"
                            append-icon="mdi-clock-time-four-outline"
                            v-bind="attrs"
                            v-on="on"
                        ></v-text-field>
                        </template>
                        <v-time-picker
                        v-if="menu2"
                        v-model="cita.hInicio"
                        full-width
                        format="24hr"
                        no-title
                        ></v-time-picker>
                    </v-menu>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="DURACIÓN">
                    <v-select clearable filled :rules="[rules.req]" v-model="cita.duracion" dense hide-details :items="[30, 60, 90, 120]" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="LUGAR CITA">
                    <v-select clearable filled v-model="cita.lugar" dense hide-details :items="espacios" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 ms-15 no-gutters" title="DETALLAR LUGAR" v-if="cita.lugar == 69">
                    <v-text-field filled hide-details dense v-model="cita.lugarDetallado"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="ESPACIO CITA">
                    <v-select clearable filled v-model="cita.espacio" dense hide-details :items="zonas" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 ms-15 no-gutters" title="DETALLAR ESPACIO" v-if="cita.espacio == 69">
                    <v-text-field filled hide-details dense v-model="cita.espacioDetallado"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="MEDIOS CITA">
                    <v-select clearable filled v-model="cita.medio" dense hide-details :items="medios" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="QUIEN RESERVA">
                    <v-select clearable filled v-model="cita.reserva" dense hide-details :items="reserva" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="QUIEN ATIENDE">
                    <v-select clearable filled :rules="[rules.req]" v-model="cita.atiende" dense hide-details :items="atiende" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <v-divider class="my-3"></v-divider>
                <AuxInput class="mx-2 no-gutters" title="TIPO EVENTO">
                    <v-select clearable filled v-model="cita.tipo" dense hide-details :items="tipoEventos" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="MOTIVO">
                    <v-select clearable filled v-model="cita.motivo" dense hide-details :items="motivos" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 ms-15 no-gutters" title="DETALLAR MOTIVO" v-if="cita.motivo == 69">
                    <v-text-field filled hide-details dense v-model="cita.motivoDetallado"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="CONFIRMADO">
                    <v-select clearable filled v-model="cita.confirmado" dense hide-details :items="confirmado" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <v-divider class="my-3"></v-divider>
                <AuxInput class="mx-2 no-gutters" title="FECHA EVENTO">
                    <v-menu ref="menu" v-model="menu3" :return-value.sync="date" transition="scale-transition" offset-y min-width="auto" :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field placeholder="Por definir" :value='cita.fechaEv != null ? ((new Date(cita.fechaEv).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(cita.fechaEv).getDate()).slice(-2)) + (new Date(cita.fechaEv).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(cita.fechaEv).getFullYear()) : null' append-icon="mdi-calendar" readonly filled hide-details dense clearable v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker @click:date="menu3 = false" no-title v-model="cita.fechaEv" :first-day-of-week="1" scrollable>
                        </v-date-picker>
                    </v-menu>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="EXISTE FICHA">
                    <v-select clearable filled v-model="cita.ficha" dense hide-details :items="ficha" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="NOMBRE CLIENTE">
                    <v-text-field filled hide-details dense v-model="cita.cliente"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="UBICACIÓN EVENTO">
                    <v-select clearable filled v-model="cita.ubicacion" dense hide-details :items="ubicaciones" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 ms-15 no-gutters" title="DETALLAR UBICACIÓN" v-if="cita.ubicacion == 10">
                    <v-text-field filled hide-details dense v-model="cita.ubicacionDetallada"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="PERSONAS">
                    <v-text-field filled hide-details dense v-model="cita.personas"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="TELÉFONO">
                    <v-text-field filled hide-details dense v-model="cita.tlf"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="EMAIL">
                    <v-text-field filled hide-details dense v-model="cita.email"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="NOTAS">
                    <v-text-field filled hide-details dense v-model="cita.notas"></v-text-field>
                </AuxInput>
                <v-btn color="primary" class="my-2" @click="save"><v-icon>mdi-floppy</v-icon>GUARDAR</v-btn>
            </v-container>
        </v-form>
        <ConfirmDialog ref="confirm" />
    </v-card>
</template>


<script>

import { req } from "@/utils/validations.js";
export default {
    props: ["idCita"],
    components: {
        AuxInput: () => import("@/components/AuxInput.vue"),
        ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    },
    computed: {
        auxModel: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit("input", v);
            },
        },
    },
    data() {
        return {
            rules: { req },
            saving: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            espacios:[
                {title:"PISCIS", val : 1},
                {title:"HOTEL", val : 2},
                {title:"LAUS", val : 3},
                {title:"LALANNE", val : 4},
                {title:"OTROS", val : 69},],
            ubicaciones:[
                {title:"PISCIS", val : 1},
                {title:"HOTEL", val : 2},
                {title:"LAUS", val : 3},
                {title:"LALANNE", val : 4},
                {title:"CATERING", val : 10},],
            motivos:[
                {title:"EVENTO NUEVO", val : 1},
                {title:"REPASO BODAS", val : 2},
                {title:"DUDAS", val : 3},
                {title:"VISITA ESPACIO", val : 4},
                {title:"VISITA TÉCNICA CATERING", val : 5},
                {title:"OTROS", val : 69},],
            zonas:[
                {title:"Z. COMERCIAL", val : 1},
                {title:"S. JUNTAS", val : 2},
                {title:"HALL", val : 3},
                {title:"BODEGA", val : 4},
                {title:"OTROS", val : 69},],
            medios:[
                {title:"NINGUNO",val:1},
                {title:"ZOOM",val:2},
                {title:"TRADICIONAL",val:3},
                {title:"IPAD",val:4},
            ],
            confirmado:[
                {title:"NO", val : 0},
                {title:"SI", val : 1},],
            ficha:[
                {title:"NO", val : 0},
                {title:"SI", val : 1},],
            tipoEventos:[
                {title:"BODA", val : 1},
                {title:"COMUNIÓN", val : 2},
                {title:"CORPORATIVO", val : 4},
                {title:"EVENTO", val : 3},
                {title:"PRUEBA BODA", val : 5},
                {title:"VACACIONES", val : 100}],
            reserva:[
                {title:"LAURA", val : 1}, 
                {title:"BERTA", val : 2}, 
                {title:"ISABEL", val : 3}, 
                {title:"MONICA", val : 4},
                {title:"BELEN", val : 7}],
            atiende:[
                {title:"LAURA", val : 1}, 
                {title:"BERTA", val : 2}, 
                {title:"ISABEL", val : 3}, 
                {title:"MONICA", val : 4},
                {title:"BELEN", val : 7},
                {title:"JAVI", val : 5}, 
                {title:"JOSE", val : 6},],
            menu: false,
            menu2: false,
            menu3: false,
            valid: true,
            cita: {
                fecha: null,
                hInicio: null,
                duracion: null,
                lugar: null,
                lugarDetallado: null,
                espacio: null,
                espacioDetallado: null,
                medio: null,
                reserva: null,
                atiende: null,
                tipo: null,
                motivo: null,
                motivoDetallado: null,
                confirmado: null,
                fechaEv: null,
                ficha: null,
                cliente: null,
                ubicacion: null,
                ubicacionDetallada: null,
                personas: null,
                tlf: null,
                email: null,
                notas: null,
            }
        };
    },
    methods:{
        async duplicar() {
        try {
            const { data } = await axios({
            url: `/citas/duplicar/${this.idCita}`,
            method: "POST",
            });
            this.$root.$emit("snack", "La cita se ha duplicado correctamente");
            this.$router.push({path: "/citas"})
        } catch (error) {
            this.$root.$emit("snack", "No se ha podido duplicar la cita");
            console.error(error);
        }
        },
        async del(){
            const confirmation =
                (await this.$refs.confirm.open(
                "Eliminar cita",
                "También se eliminará la cita. Esta acción NO se puede deshacer.",
                { color: "primary" }
                ));
            if (!confirmation) return;

            try {
                this.saving = true;
                const id = await axios({
                    method: 'DELETE',
                    url: `${process.env.VUE_APP_API_URL}/citas/${this.idCita}`,
                })
                this.saving = false;

                this.$root.$emit("snack", "Eliminada correctamente");
                this.$router.push({ name: 'citas'}).catch(()=>{})

            } catch (err){
                this.saving = false;
                console.error(err);
                this.$root.$emit("snack", "No se ha podido eliminar correctamente");
            }
        },
        async save(skip) {
            if (!this.$refs.form.validate()) {
                this.$root.$emit("snack", "ERROR: Asegurate de haber rellenado los campos obligatorios");
                return;
            }
            const confirmation =
                skip ||
                (await this.$refs.confirm.open(
                "Guardar los cambios",
                "¿Estás seguro de que quieres guardar este cita?",
                { color: "primary" }
                ));
            if (!confirmation) return;

            try {
                this.saving = true;
                const id = await axios({
                        method: 'POST',
                        url: `${process.env.VUE_APP_API_URL}/citas/nuevo`,
                        data: {
                            items: JSON.stringify({datos : this.cita, idCita : this.idCita }),
                        }
                    })
                this.saving = false;

                this.$root.$emit("snack", "Se han guardado los cambios correctamente");
                this.$router.push({ name: 'citas'}).catch(()=>{})

            } catch (err){
                this.saving = false;
                console.error(err);
                this.$root.$emit("snack", "No se han podido guardar los cambios");
            }
		},
        async getCita() {
            if (this.idCita){
                try {
                    const { data } = await axios({
                            method: 'GET',
                            url: `${process.env.VUE_APP_API_URL}/citas/${this.idCita}`,
                        })
                    this.$root.$emit("snack", "cita cargada correctamente");
                    this.cita = data.datos;
                    if (!this.cita.fecha) this.cita.fecha = new Date();
                } catch (err){
                    this.saving = false;
                    console.error(err);
                    this.$root.$emit("snack", "No se han podido cargar los datos de esta cita");
                }
            }
            else {
                this.cita.fecha = new Date().toISOString().split('T')[0];
            }
        }
    },
    mounted(){
        this.getCita()
    }
};
</script>